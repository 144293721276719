import { useState, useEffect } from "react";
import classes from './ProgressiveImg.module.css'

const ProgressiveImg = ({ placeholderSrc, src, ...props }) => {
  const [imgSrc, setImgSrc] = useState(placeholderSrc || src);

  useEffect(() => {
    const img = new Image();
    
    img.src = src;
    img.onload = () => {
      setImgSrc(src);
    };
  }, [src]);

  const customClass =
    placeholderSrc && imgSrc === placeholderSrc ? `${classes.loading}` : `${classes.loaded}`;

  return (
    <img
      {...{ src: imgSrc, ...props }}
      alt={props.alt || ""}
      className={`${classes.image} ${customClass} ${props.className ? props.className : ""}`}
      onClick={props.onClick}
    />
  );
};
export default ProgressiveImg;