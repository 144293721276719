import React,{useContext} from "react";
import EventContext from '../../store/event-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faHome } from '@fortawesome/free-solid-svg-icons'
import FolderLink from './FolderLink'
import classes from './Breadcrumb.module.css'

const Breadcrumb = (props) => {
    const ctx = useContext(EventContext);

    //console.log("breadcrumb->props.currentFolder=", props.currentFolder)
    const page = props.currentFolder.nameWithPrefix;
    let folders = [];
 
    if (ctx.identifier !== props.currentFolder.name)
    {
        folders = page.slice(0,page.length-1).split('/');
    }
    //console.log("breadcrumb->folders=", folders)

    const getPartialUrl = (index) =>
    {
        let partialUrl = "";
        if (index < folders.length)
        {
            for (let i = 0; i <= index; i++) {
                partialUrl += folders[i]+ "/";
            } 
        }

        return partialUrl;            
    }

    return (
        <>
            {
                folders.length > 0 && 
                <ul id="breadcrumb">
                    <li>
                        <FolderLink
                                onFolderChange={ctx.onFolderChange}
                                to={`/${ctx.language}/${ctx.identifier}/`}
                                folderName="home" 
                                className={classes.link} 
                        >
                            <FontAwesomeIcon icon={faHome} className={classes.link} />
                        </FolderLink>

                        <FontAwesomeIcon icon={faCaretRight} className={classes.arrow}/>  
                    </li>
                    
                    {folders.map((folder,i) => (
                        <li key={i}>
                            {
                            i< folders.length -1 &&
                            <FolderLink
                            onFolderChange={ctx.onFolderChange}
                            to={`/${ctx.language}/${ctx.identifier}/${getPartialUrl(i)}`}
                            folderName={folder} 
                            className={classes.link} 
                            >
                            <span >{folder}</span><FontAwesomeIcon icon={faCaretRight} className={classes.arrow}/>
                            </FolderLink>		

  
                         }
                         {
                            i === folders.length -1 &&
                            <span key={i}>{folder}</span>
                         }
                        </li>
                         
                        
                    ))}      
                </ul>
            }
        </>
       
    );
}

export default Breadcrumb;