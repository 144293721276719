import React, {useContext} from 'react'
import EventContext from '../../store/event-context';
import LoadingSpinner from '../UI/LoadingSpinner';
import PageNotFound from "../UI/PageNotFound"
import classes from './EventCover.module.css'
import ProgressiveImg from '../UI/ProgressiveImg';

const EventCover = (props) => {
	const ctx = useContext(EventContext);

	if (props.isLoading) 
		return (
			<div className="row h-100 align-items-center centered" id="gallery-cover">
				<LoadingSpinner />
			</div>
			);

	if (props.error) 
	{
		console.log("Event cover error!");
		console.error(props.error);
		const errorMessageFR="Une erreur s'est produite lors du chargement des détails de la galerie.";
		const errorMessageNL="Er is een fout opgetreden bij het laden van galerijdetails.";
		return (
			<div className="row h-100 align-items-center centered" id="gallery-cover">
                <h1>{ctx.language === 'fr' ? errorMessageFR : errorMessageNL}</h1>
			</div>
			);
	}
			
	if (!props.cover) 
	{
		//console.log("+++ event cover null => page not found ");
		return (<PageNotFound />);
	}
/*    THESE ARE THE IMAGES SIZES RETURNED BY API: 
public int PhotoSmallWidth { get { return 240; } }
public int PhotoMediumWidth { get { return 360; } }
public int PhotoLargeWidth { get { return 640; } }
public int PhotoXLargeWidth { get { return 1024; } }
public int PhotoXXLargeWidth { get { return 1600; } } */	
/*
const screenWidth = window.innerWidth 
          || document.documentElement.clientWidth
          || document.body.clientWidth;	
*/
	
	let cover = props.cover.imageCoverBlob.pathLarge;
	let coverPlaceholder = props.cover.imageCoverBlob.pathLargeMin;
	if (ctx.screenWidth >= 750){
		cover = props.cover.imageCoverBlob.pathXLarge;
		coverPlaceholder = props.cover.imageCoverBlob.pathXLargeMin;
	}

	return (
		
			<div className="row align-items-center h-100 centered" id="gallery-cover" style={{marginBottom: "20px"}}>
				<div className="col-sm-6 col-md-6 " style={{paddingTop:"10px", paddingBottom:"10px"}}>
				<ProgressiveImg
					src={cover}
					placeholderSrc={coverPlaceholder}
				/>
				
				</div>
				<div className="col-sm-6 col-md-6  ">
					<h1 >{ctx.language === 'fr' ? props.cover['title_FR'] : props.cover['title_NL']}</h1>
					<h4 >{props.cover['eventDateDisplay']} </h4>
					<p>{ctx.language === 'fr' ? props.cover['description_FR'] : props.cover['description_NL']}</p>
					{
						props.cover &&
						<div>
							<button className={classes.button} onClick={props.onJumpToGallery.bind(this)}> {ctx.language === 'fr' ? 'Voir la galerie' : 'Galerij bekijken'} </button>
						</div>
					}
				</div>
			</div>
		)

}

export default EventCover;