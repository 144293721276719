import React, {useContext} from "react";
import EventContext from '../../store/event-context';

const PageNotFound = () => {
    const ctx = useContext(EventContext);
    const errorMessageFR="Page non trouvée!";
    const errorMessageNL="Pagina niet gevonden!"
    //console.log("Page not found!!!!!!!!!!!!!!!!!!!");
    return(
        <div className="row h-100 align-items-center centered">
            <h1>{ctx.language === 'fr' ? errorMessageFR : errorMessageNL}</h1>
        </div>        
    )
}

export default PageNotFound;