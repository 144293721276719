import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect, useCallback} from 'react';
import {Route, Routes, useNavigate, useLocation} from 'react-router-dom';
import axios from 'axios'

import Header from './components/Layout/Header';
import Footer from "./components/Layout/Footer";
import EventCover from "./components/Gallery/EventCover";
import EventGallery from "./components/Gallery/EventGallery";
import PageNotFound from "./components/UI/PageNotFound"
import LoadingSpinner from './components/UI/LoadingSpinner';

import './App.css';
import EventContext from "./store/event-context";
import { API_URL, ENV_NAME } from "./environment";


const App = () => {

	const [language, setLanguage] = useState("");
	const [isLanguageChange, setIsLanguageChange] = useState(false);
	const [eventIdentifier, setEventIdentifier] = useState("");
	
	const [cover, setCover] = useState(null);
	const [coverIsLoading, setCoverIsLoading] = useState(false);
	const [coverError, setCoverError] = useState(null);

	const [galleryIsLoading, setGalleryIsLoading] = useState(false);
	const [galleryIsPending, setGalleryIsPending] = useState(true);
	const [galleryError, setGalleryError] = useState(null);

	const [currentFolder, setCurrentFolder] = useState(null);
	const [nextFolder, setNextFolder] = useState(null);

	const navigate = useNavigate();
	const location = useLocation();

	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	
    //-----------------------------------------------------
	useEffect(() => {
	 	const handleResizeWindow = () => setScreenWidth(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth);

		// subscribe to window resize event "onComponentDidMount"
		window.addEventListener("resize", handleResizeWindow);

		return () => {
			// unsubscribe "onComponentDestroy"
			window.removeEventListener("resize", handleResizeWindow);
		};
	}, []);

	//--- EVENT HANDLERS ------------------------------------------------------   
    const handleLanguageChange = (newValue) => {
		navigate(`/${newValue}/${location.pathname.slice(4)}`);
		setIsLanguageChange(true);
		setLanguage(newValue);
    };
	//---------------------------------------------------------
	const handlerJumpToGallery = (e) => {
		e && e.preventDefault(); // to avoid the link from redirecting
		const elementToView = document.getElementById("gallery-title");
		elementToView && elementToView.scrollIntoView(); 
	}
	//----------------------------------------------------------
	const handleFolderChange = () =>{
		setIsLanguageChange(false);
	}
	//--- API CALLS ------------------------------------------------------
	
	//---------------------------------------------------------
	useEffect(()=> {
				
		if (!isLanguageChange)
		{
			
			const path = ` ${location.pathname} `.replace(" /", " ").replace("/ ", " ").trim().replace("%20", " ");
			const locationKeys = path.split('/');
			let folderName = "";
			let folderNameWithPrefix = "";

			console.log("location change -> locationKeys=", locationKeys);
			
			
			if (locationKeys.length >= 1)
			{
				if(locationKeys[0] !== language)
				{
					setLanguage(locationKeys[0] === 'fr' || locationKeys[0] === 'nl' ? locationKeys[0] : "");	
					setIsLanguageChange(false);
				}
			}
			
			if (locationKeys.length >= 2)
			{
				if (locationKeys[1] !== eventIdentifier)
				{
					//console.log("new event=", locationKeys[1]);
					setEventIdentifier(locationKeys[1]);
				}
				
				if (locationKeys.length > 2)
				{
					folderName = locationKeys[locationKeys.length-1];
					folderNameWithPrefix = `${path.slice(locationKeys[1].length+4)}/`;
					//console.log("new folder=", folderName);
				}
				setNextFolder({name: folderName, nameWithPrefix:  folderNameWithPrefix});
			}
		}
		
	},[location, language,eventIdentifier,isLanguageChange]);
	//---------------------------------------------------------
	const getCover = useCallback(async () => {

		setCoverIsLoading(true);
		setCoverError(null);

		console.log("-------->START LOAD COVER!!!!!!!!!!!")
		const COVER_API_PARAMS={env:`${ENV_NAME}`,lang:"nl-NL",method:"GetEventDetails",parameters:{eventName: `${eventIdentifier}` }};
		try{
			const response = await axios
					.post(API_URL,COVER_API_PARAMS)

			if (!response.data || response.data.length === 0) 
			{
				throw new Error("Photo gallery not found!");
			}
			setCover(response.data);
			//console.log("cover=", response.data);			
			
		}
		catch (error){
			setCoverError(error.message);
			console.log("load cover with error, ", error);
		}
		setCoverIsLoading(false);	
		console.log("---------> END LOAD COVER!!!!!!!!!!!")
	},[eventIdentifier]);

	//--- UseEffect --------------------------------------------------------------------------------------------------
	useEffect(()=> {
		if (cover)
			{
				document.title = `LNL ${language === 'fr' ? cover['title_FR'] : cover['title_NL']}`;
			}
	},[cover, language])

	//---------------------------------------------------------

	useEffect(()=> {
		// api call to get event details
		if (eventIdentifier)
		{
			getCover();
		}
	},[getCover,eventIdentifier])

	//---------------------------------------------------------

	const getPhotos = useCallback(async () => {
		setGalleryIsLoading(true);
		setGalleryIsPending(true);
		setGalleryError(null);
		const folderName = nextFolder ? nextFolder.name : "";
		const folderNameWithPrefix = nextFolder ? nextFolder.nameWithPrefix : "";
		//console.log("getPhotos params=", folderName,", ", folderNameWithPrefix);
		console.log("-------------> START LOAD PHOTOS !!!!!");
		const PHOTOS_API_PARAMS={env:`${ENV_NAME}`,lang:"nl-NL",method:"GetPhotos",parameters:{eventName: `${eventIdentifier}` , folderName: folderName, folderPrefix: folderNameWithPrefix}};
		try{
			if (eventIdentifier)
			{
				const response = await axios
					.post(API_URL,PHOTOS_API_PARAMS);

				//console.log("response=", response);

				if (response && response.data && response.data['blobPhotos'])
				{
					//console.log("get images from root");
					setCurrentFolder(response.data);
				}
			}
		}
		catch (error){ 
			
			setGalleryError(error.message);
			console.log("load photos with error,", error);
		}
		setGalleryIsLoading(false);	
		setGalleryIsPending(false);
		console.log("-------------> END LOAD PHOTOS !!!!!");
	},[eventIdentifier,nextFolder]);

	//--- UseEffect => GetPhotos ---------------------------------------------------------------------------------
	useEffect(()=> {
		if (eventIdentifier !== "" && coverIsLoading === false && coverError === null && cover !== null && cover !== undefined)
			{
				getPhotos();
			}
	},[getPhotos,eventIdentifier,coverIsLoading,coverError, cover])

	//==========================================================================================================
  	return (
		<EventContext.Provider 
			value={{
				language: language,
				identifier: eventIdentifier,
				onFolderChange: handleFolderChange,
				screenWidth: screenWidth
			}}
		>
			
			<Header onLanguageChange={handleLanguageChange} />
			
			<Routes>
				<Route path={ `/${language}/${eventIdentifier}/*`} element={			
					<main className="mainContainer h-100">
					{
						//cover !== null  &&
						<EventCover
							error={coverError}
							isLoading={coverIsLoading}
							cover={cover}
							onJumpToGallery={handlerJumpToGallery}
						/>
					}

					{galleryIsPending === true && 

						<div className="row h-100 align-items-center centered" id="gallery-photos">
							<LoadingSpinner />
						</div>
					}
	
						{galleryIsPending === false && !coverIsLoading && //coverError === null && cover !== null && 
						<>
						
							<h1 className="title" id="gallery-title"> {language === 'fr' ? cover['title_FR'] : cover['title_NL']}</h1>
							<EventGallery
								error={galleryError}
								isLoading={galleryIsLoading}
								eventName={language === 'fr' ? cover['title_FR'] : cover['title_NL']}
								currentFolder={currentFolder} 
								
							/>
						</>
						}
						
					</main>		
				}/>
					
				<Route path='*' element={<PageNotFound />}/>
				<Route path='//' element={<PageNotFound />}/>
			</Routes>
			<Footer />

		</EventContext.Provider>
  	);
}

export default App;
