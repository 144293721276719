import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
      return { hasError: true };
    }
   
    componentDidCatch(error, errorInfo) {
       
        console.log("Error caught by ErrorBoundery!")
        console.log("-> error=",error.message);
        //log the error to an error reporting service
        //errorService.log({ error, errorInfo });
        console.log("-> errorInfo=", errorInfo);
        /*
        //this is not a good solution to logg error, it can generate new error if the server is not responding.
        const API_PARAMS={env:`${ENV_NAME}`,lang:"nl-NL",method:"LogError",parameters:{error: error.message, url: "" }};
        try{
          const response = axios
              .post(API_URL,API_PARAMS)
        }
        catch (error)
        {
          console.log("The error logging failed!");
        }
        */
    }
  
    render() {
      if (this.state.hasError) {
        return <h1>Oeps! Er is iets misgegaan/Oups, quelque chose s'est mal passé.</h1>;
      }
      return this.props.children; 
    }
  }

  export default ErrorBoundary;