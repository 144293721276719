import {PhotoAlbum} from "react-photo-album";
import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import {saveAs} from 'file-saver'
import classes from './Photos.module.css';
import LoadingSpinner from '../UI/LoadingSpinner';
import imagePlaceholder from '../../images/image_placeholder.jpg';
import EventContext from '../../store/event-context';


import {
    faCircleChevronLeft, 
    faCircleChevronRight, 
    faCircleXmark,
    faDownload
  } from '@fortawesome/free-solid-svg-icons'

const Gallery = (props) => {

    const ctx = useContext(EventContext);
    const [slideNumber, setSlideNumber] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [openDonwloadMenu, setOpenDownloadMenu] = useState(false);

    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)

    //-----------------------------------------------------------------------------------
    // the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 50 

    const onTouchStart = (e) => {
        setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX);
    }

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return

        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        if (isLeftSwipe) 
        {
            prevSlide();
        }

        if (isRightSwipe) 
        {
            nextSlide();
        }
    }
    //----------------------------------------------------------------------------------
    const handleOpenModal =(index) => {
        console.log("index=",index);
        setSlideNumber(index);
        setOpenModal(true);
        setOpenDownloadMenu(false);
    }   

    const handleDonwloadMenu =() => {
        setOpenDownloadMenu(prev => !prev);
    }   

    // Close Modal
    const handleCloseModal = () => {
        setOpenModal(false);
        setOpenDownloadMenu(false);
    }

    // Previous Image
    const prevSlide = () => {
        slideNumber === 0
        ? setSlideNumber(props.imageList.length -1)
        : setSlideNumber(slideNumber -1)      
    }

    // Next Image  
    const nextSlide = () => {
        slideNumber + 1 === props.imageList.length 
        ? setSlideNumber(0)
        : setSlideNumber(slideNumber +1)
    }
    
    function stripNameOfInvalidChars(name) {
        var forbiddenChars = ['<', '>', ':', '"', '/', '\\', '|', '?', '*',' '];
        for(var i = 0;i < forbiddenChars.length; i++) {
        name = name.replaceAll(forbiddenChars[i], '_');
        }
        return name;
      }
      
    // download button
    const downloadImage = (url) => {
        try 
        {
            var isFileSaverSupported = !!new Blob();
            if (isFileSaverSupported)
            {
                //console.log("start image donwload");
                saveAs(url,`${stripNameOfInvalidChars(props.eventName)}.jpg`);
                setOpenDownloadMenu(false);
            }
            else
            {
                console.log("File download is not supported by browser!");
                const downloadErrorMessageFR="Le téléchargement de fichier n'est pas encore pris en charge par le navigateur!";
                const downloadErrorMessageNL="Het downloaden van bestanden wordt nog niet ondersteund door de browser!";
                //display a modal with message!
            }
        } 
        catch (e) 
        {
            console.log(e);
        }
        
    }

    const handleKeyDown = (event) => {
        if (event.key === "ArrowLeft") {
            prevSlide();
        }
        if (event.key === "ArrowRight") {
            nextSlide();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
    
        return function cleanup() {
          document.removeEventListener("keydown", handleKeyDown);
        };
      });

//=========================================================================
    if (props.isLoading) 
    return (
        <div className="row  h-100 align-items-center centered" id="photos">
            <LoadingSpinner />
            <p></p><p></p>
        </div>
        );
//=========================================================================

    if (props.imageList.length === 0) 
    {
        const messageFR="Aucune photo ici. Veuillez sélectionner un sous-dossier svp.";
        const messageNL="Geen foto's hier. Selecteer een submap aub.";
        return (
            <div id="photos" className="row  h-100 align-items-center centered">
                <h1>{ctx.language === 'fr' ? messageFR : messageNL}</h1><p></p><p></p>
            </div>
            ); 
    }
 
//=========================================================================
/*    THESE ARE THE IMAGES SIZES RETURNED BY API: 
public int PhotoMediumWidth { get { return 360; } }
public int PhotoLargeWidth { get { return 640; } }
public int PhotoXLargeWidth { get { return 1024; } }
public int PhotoXXLargeWidth { get { return 1600; } } */

//---------------------------------------------------------------------------
// Use the appropiate image size depending on the screen size

    const getFullScreenImageUrl = () => {
        if (ctx.screenWidth >= 750 && ctx.screenWidth < 1024) return props.imageList[slideNumber].pathLarge;
        if (ctx.screenWidth >= 1024 && ctx.screenWidth < 1600) return props.imageList[slideNumber].pathXLarge;
        if (ctx.screenWidth >= 1600) return props.imageList[slideNumber].pathXXLarge;

        return props.imageList[slideNumber].pathMedium;
    }

    const getGalleryImageUrl = (image) =>{
        if (ctx.screenWidth >= 350 && ctx.screenWidth < 650) return image.pathLarge; // 1 col 
        if (ctx.screenWidth >= 650 && ctx.screenWidth < 750) return image.pathXLarge; // 1 col 
        if (ctx.screenWidth >= 750 && ctx.screenWidth < 800) return image.pathMedium; // 2 cols
        if (ctx.screenWidth >= 800 && ctx.screenWidth < 1400) return image.pathLarge; // 2-3 cols, 3 cols from 1024
        if (ctx.screenWidth >= 1400 && ctx.screenWidth < 2000) return image.pathLarge; //4 cols
        if (ctx.screenWidth >= 2000 && ctx.screenWidth < 4000) return image.pathXLarge; //4 cols
        if (ctx.screenWidth >= 4000) return image.pathXXLarge; //4 cols

        return image.pathMedium; //default 1 col, small mobile screen
    }

    const breakpoints = [1600, 1024, 640, 360];
    const photos = props.imageList.map((photo) => ({
        key: photo.id,
        src: photo.pathMedium,
        width: photo.width,
        height: photo.height,
        images: breakpoints.map((breakpoint) => {
            const height = Math.round((photo.height / photo.width) * breakpoint);
            if (breakpoint === 1600)  
            {
                return {
                    src: photo.pathXXLarge,
                    width: breakpoint,
                    height,
                };
            }
            if (breakpoint === 1024) 
            { 
                return {
                    src: photo.pathXLarge,
                    width: breakpoint,
                    height,
                };
            }
            if (breakpoint === 640)  
            {
                return {
                    src: photo.pathLarge,
                    width: breakpoint,
                    height,
                };
            }
            if (breakpoint === 360)  
            {
                return {
                    src: photo.pathMedium,
                    width: breakpoint,
                    height,
                };  
            } 

        }),
    }));

//--------------------------------------------------------------------------------
    return (
        <div id="photos" className={`${classes.container} row h-100 align-items-center`} >
            {openModal &&
                <div className={classes.sliderWrap}>
                    <FontAwesomeIcon icon={faCircleXmark} className={classes.btnClose} onClick={handleCloseModal} />
                    <FontAwesomeIcon icon={faCircleChevronLeft} className={classes.btnPrev} onClick={prevSlide} />
                    <FontAwesomeIcon icon={faCircleChevronRight} className={classes.btnNext} onClick={nextSlide} /> 
                    <FontAwesomeIcon icon={faDownload} className={classes.btnDownload} onClick={handleDonwloadMenu}  alt=''/> 
                    {
                        openDonwloadMenu &&
                        <div className={classes.donwloadMenu}>
                            <button 
                                key="webSize" 
                                onClick={ () => downloadImage(props.imageList[slideNumber].pathXXLarge)}
                            >{ctx.language === 'fr' ? "Basse résolution 1600px" : "Lage resolutie 1600px"}
                            </button><br/>
                            <button 
                                key="originaleSize"
                                onClick={ () => downloadImage(props.imageList[slideNumber].url)}
                            >{ctx.language === 'fr' ? "Haute résolution" : "Hoge resolutie"}
                            </button>
                        </div>

                    }
                    
                    
                    <div className={classes.fullScreenImage} 
                        onTouchStart={onTouchStart} 
                        onTouchMove={onTouchMove} 
                        onTouchEnd={onTouchEnd} 
                    >

                        <img                        
                            srcSet={getFullScreenImageUrl()}                                
                            alt={""}                                                                                    
                        />   
                    </div>    
                </div>
            }

            <PhotoAlbum 
                layout="masonry" 
                columns={(containerWidth) => {
                    if (containerWidth < 450) return 1;
                    if (containerWidth < 800) return 2;
                    if (containerWidth < 1400) return 3;
                    return 4;//350: 1, 750: 2, 1023: 3, 1400: 4
                }}
                spacing="10"
                componentsProps={(containerWidth) => ({
                    imageProps: { className: 'gridimg', loading: "lazy" },
                })}
                photos={photos} 
                onClick={({ index }) => {
                    handleOpenModal(index);
                }}
                

                
               
                
            />
            <p></p>. {/*The dot is important! It adds same space beetwen the gallery and footer. */}
        </div>    
    )
}

export default Gallery;

