import React from "react";
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';

export const useNavigateToGallery = () => {
  const navigate = useNavigate();

  const navigateAndReset = (to) => {
    navigate(to, { replace: true });
    const section = document.querySelector( '#gallery-title' );
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  };

  return navigateAndReset;
};
//-------------------------------------------------------------------------------------------
const FolderLink = (props) => {
    const navigateToGallery = useNavigateToGallery();

    const navigateAndReset = (event) => {
      event.preventDefault();
      navigateToGallery(props.to);

      props.onFolderChange();
    };

    return(
        <Link 
            onClick={navigateAndReset}
            to={props.to}
            key={props.folderName} 
            className={props.className} 
            >{props.children}
        </Link>	
    )
}

export default FolderLink;