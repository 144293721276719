import React , {useContext} from 'react'
import EventContext from '../../store/event-context';
import logo_baseline_fr from '../../images/Logo_Loterie_Baseline_FR_min.png';
import logo_baseline_nl from '../../images/Logo_Loterie_Baseline_NL_min.png';
import classes from './Header.module.css'

const Header = (props) => {
    const ctx = useContext(EventContext);
    return (
        <header className='fixed-top'>  
            <nav className={`navbar  ${classes.header}`}>
                <div className={`col-6 ${classes.headerLogo}`}>
                    <img src={ctx.language === 'fr' ? logo_baseline_fr : logo_baseline_nl} alt=""></img>
                </div>
                <div className={`col-6 ${classes.headerLanguage}`}>
                    <button className={`${classes.link} ${ctx.language === 'fr' ? classes.activeLink : ''}`}  key='fr' onClick={ () => props.onLanguageChange('fr')} >Français</button>&nbsp;|&nbsp; 
                    <button className={`${classes.link} ${ctx.language === 'nl' ? classes.activeLink : ''}`} key='nl' onClick={ () => props.onLanguageChange('nl')} alt="">Nederlands</button>
                </div>
            </nav>    
        </header>
    )
       
}

export default Header