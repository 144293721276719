import React,{useContext} from "react";
import Photos from './Photos';
import Photos_NEW from './Photos_NEW';

import Breadcrumb from "../Navigation/Breadcrumb";
import Subfolders from '../Navigation/Subfolders';
import LoadingSpinner from '../UI/LoadingSpinner';
import EventContext from '../../store/event-context';
import classes from './EventGallery.module.css'

const EventGallery = (props) => {
    const ctx = useContext(EventContext);
    /*
    if (props.isLoading) 
    return (
        <div className="row h-100 align-items-center centered" id="gallery-photos">
            <LoadingSpinner />
        </div>
        );
*/
    if (props.error !== null || props.currentFolder === null) 
    {
        console.log("Event photos error!");
        console.log("error=", props.error);
        console.log("currentFolder=", props.currentFolder);
        
        const errorMessageFR="Une erreur s'est produite lors du chargement des photos de la galerie.";
        const errorMessageNL="Er is een fout opgetreden bij het laden van galerijfoto's.";
        return (
            <div className="row  h-100 align-items-center centered" id="gallery-photos">
                <h1>{ctx.language === 'fr' ? errorMessageFR : errorMessageNL}</h1>
            </div>
            );
    }

    return(
        <div className={`${classes.galleryPhotos} row h-100`} id="gallery-photos">
            <div className={classes.galleryNavigation}>
                <Breadcrumb currentFolder={props.currentFolder} onFolderChange={props.onFolderChange} />
                <Subfolders currentFolder={props.currentFolder} onFolderChange={props.onFolderChange} />      
            </div>

            <Photos_NEW 
                isLoading={props.isLoading}
                eventName={props.eventName}						
                imageList={props.currentFolder['blobPhotos']}
            />


        </div>
    )
}

export default EventGallery;