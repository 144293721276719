import React, {useContext} from 'react'
import classes from './Footer.module.css'
import EventContext from '../../store/event-context';

const Footer = (props) => {
    const ctx = useContext(EventContext);

    return (
        <footer className={`${classes.footer} fixed-bottom`}>
            © {new Date().getFullYear()} {ctx.language === 'fr' ? 'Loterie Nationale' : 'Nationale Loterij'}
        </footer>
    )
}

export default Footer