import React,{useContext} from "react";
import EventContext from '../../store/event-context';
import classes from './Subfolders.module.css'
import FolderLink from './FolderLink'

const Subfolders = (props) => {
    const ctx = useContext(EventContext);

    return (
        <ul id="subfolders" className={classes.container}>
            {props.currentFolder.blobSubfolders.map((subfolder) => (
            <li key={subfolder.name}>
                <FolderLink
                        onFolderChange={ctx.onFolderChange}
                        to={`/${ctx.language}/${ctx.identifier}/${subfolder.name !== ctx.identifier ? subfolder.nameWithPrefix : ""}`}
                        folderName={subfolder.name} 
                        className={classes.link} 
                >
                    {subfolder.name}
                </FolderLink>
            </li>
            ))}
        </ul>
    );
}

export default Subfolders;